import React from "react";

export function Problems() {
  return (
    <div className="row ">
      <div className=" col-xl-6 col-lg-6">
        <img
          style={{ width: "100%" }}
          src="https://cdn.discordapp.com/attachments/165746589309206528/757955068682043483/undraw_goals_w8tw_2.png"
          alt=""
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        className="mobile-margin-top col-xl-6 col-lg-6"
      >
        <h3 style={{ marginBottom: "1rem" }}>Problems and Thought Process</h3>
        <p>
          I made the pokedèx almost entirely with Photoshop and used it as
          background image, so place every component to its place made me code a
          lot of hard code and didn't help when I had to optimize for smaller
          screen sizes.
          <br />
        </p>
      </div>
    </div>
  );
}
